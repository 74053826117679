import dayjs from "dayjs";

var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

// const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})

export const ContractStatStore = {
    namespaced: true,
    state() {
        return {
            contract: null,
            year: dayjs().year(),
        }
    },
    mutations: {
        setContract(state, value) {
            state.contract = value;
        },
        setYear(state, value) {
            state.year = value;
        }
    },
    getters: {
        contract(state) {
            return state.contract;
        },
        year(state) {
            return state.year;
        },
        supplementaire_realise_mensuel_par_contrat(state, getters, rootState, rootGetters) {
            let entries_per_contract = rootGetters["finance/allEntries"]
                .map(entry => ({year: dayjs(entry.date_gestion).year(), month: dayjs(entry.date_gestion).month(), ...entry}))
                .filter(entry => entry.year === state.year && entry.contract === state.contract && entry.type_depense === "Dépense sous contrat supplémentaire");
            let entries_monthly_per_contract = groupBy(entries_per_contract, "month");
            let supplementaire_realise_par_contrat = {};
            for (const [month, entries] of Object.entries(entries_monthly_per_contract)) {
                supplementaire_realise_par_contrat[month] = entries
                    .map(entry => parseFloat(entry.realise))
                    .reduce((a, b) => a + b, 0);
            }
            let series = [];
            for (let i = 0; i < 12; i++) {
                series.push(supplementaire_realise_par_contrat[i] || 0);
            }
            return series;
        },
        sous_contrat_realise_mensuel_par_contrat(state, getters, rootState, rootGetters) {
            let entries_per_contract = rootGetters["finance/allEntries"]
                .map(entry => ({year: dayjs(entry.date_gestion).year(), month: dayjs(entry.date_gestion).month(), ...entry}))
                .filter(entry => entry.year === state.year && entry.contract === state.contract && entry.type_depense === "Dépense sous contrat");
            let entries_monthly_per_contract = groupBy(entries_per_contract, "month");
            let sous_contrat_realise_par_contrat = {};
            for (const [month, entries] of Object.entries(entries_monthly_per_contract)) {
                sous_contrat_realise_par_contrat[month] = entries
                    .map(entry => parseFloat(entry.realise))
                    .reduce((a, b) => a + b, 0);
            }
            let series = [];
            for (let i = 0; i < 12; i++) {
                series.push(sous_contrat_realise_par_contrat[i] || 0);
            }
            return series;
        },
        controles_reglementaires(state, getters, rootState, rootGetters) {
            return rootGetters["contracts/allContracts"]
                .filter(contrat => contrat.type === "controle")
                .map(contrat => ({
                    ...contrat,
                    remaining_time: dayjs(contrat.date_end).diff(dayjs(), "day"),
                    nbre_assets: contrat.assets.length,
                }));
        }
    }
}