import axios from "axios";
import config from "../config";
import {AssetFilterStore} from "@/store/assets/filter";
import {AssetStatStore} from "@/store/assets/stats";

export const assetStore = {
    namespaced: true,
    state: () => ({
        "assets": [],
        "selected_asset": null,
    }),
    mutations: {
        addAsset(state, asset) {
            state.assets = [asset, ...state.assets];
        },
        setAssets(state, assets) {
            state.assets = assets;
        },
        appendAssets(state, assets) {
            state.assets = [...state.assets, ...assets]
        },
        updateAsset(state, asset) {
            state.assets = state.assets.map((item) => item.id === asset.id ? asset : item);
        },
        removeAsset(state, asset) {
            state.assets = state.assets.filter((item) => item.id !== asset.id);
        },
        setSelectedAsset(state, asset) {
            state.selected_asset = asset;
        },
        setStatus(state, status) {
            state.status = status;
        }
    },
    actions: {
        async fetchAssets({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/assets/", axiosConfig);
                commit('setAssets', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async fetchAssetById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/assets/${id}/?include=sites`, axiosConfig)
                commit('updateAsset', response.data);
                commit('setSelectedAsset', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async getAssetByBalise({dispatch, rootGetters}, balise) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/assets/?balise=${balise}`, axiosConfig)
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return [];
            }
        },
        async postAsset({commit, dispatch, rootGetters}, asset) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + "/api/assets/", asset, axiosConfig);
                var newAsset = response.data;
                commit("addAsset", newAsset);
                commit("setSelectedAsset", newAsset);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        /**
         * Mettre à jour le asset
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param asset
         */
        async putAsset({commit, dispatch, rootGetters}, asset) {
            const url = config.BASE_URL + "/api/assets/" + asset.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.patch(url, asset, axiosConfig);
                var newAsset = response.data;
                commit("updateAsset", newAsset);
                commit("setSelectedAsset", newAsset);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },

        async deleteAsset({commit, dispatch, rootGetters}, asset) {
            const url = config.BASE_URL + "/api/assets/" + asset.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.delete(url, axiosConfig);
                commit("removeAsset", asset);
                commit("setSelectedAsset", null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
    },
    getters: {
        allAssets(state) {
            return state.assets;
        },
        getSelectedAsset(state) {
            return state.selected_asset;
        },
        getInterventionAsset: (state, getters, _, rootGetters) => (intervention) => {
            let ticket = rootGetters["tickets/allTickets"].find((ticket) => intervention.ticket === ticket.id);
            return ticket !== undefined ? ticket.asset : null;
        },
        getAssetByIdCached: (state, getters) => (id) => {
            return getters["allAssets"].find((asset) => asset.id === id);
        },
        allAssetsCategories(state, getters) {
            let assetsCategories = [];
            let usedIds = [];
            assetsCategories.push({"label": "Toutes", value: ""})
            for (let asset of getters["allAssets"]) {
                if (asset.category !== null) {
                    if (usedIds.indexOf(asset.category.id) === -1) {
                        assetsCategories.push({"value": asset.category.id, "label": asset.category.name});
                        usedIds.push(asset.category.id);
                    }
                }
            }
            return assetsCategories;
        }
    },
    modules: {
        filter: AssetFilterStore,
        stats: AssetStatStore,
    },
};