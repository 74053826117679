import axios from "axios";
import config from "../config";

export const commentaireStore = {
    namespaced: true,
    state() {
        return {
            "commentaires": [],
            "selected_commentaire": null,
        };
    },
    mutations: {
        addCommentaire(state, commentaire) {
            state.commentaires.push(commentaire);
        },
        setCommentaires(state, commentaires) {
            state.commentaires = commentaires;
        },
        updateCommentaire(state, commentaire) {
            state.commentaires = state.commentaires.map((item) => item.id === commentaire.id ? commentaire : item);
        },
        removeCommentaire(state, commentaire) {
            state.commentaires = state.commentaires.filter((item) => item.id !== commentaire.id)
        },
        setSelectedCommentaire(state, commentaire) {
            state.selected_commentaire = commentaire;
        }
    },
    actions: {
        async fetchCommentairesByTicket({commit, dispatch, rootGetters}, ticket) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/tickets/" + ticket.id + "/commentaires/", axiosConfig)
                commit('setCommentaires', response.data);
            } catch (e) {
                dispatch('alerts/handleAxiosError', e, {root: true})
            }
        },
        async fetchCommentairesByContract({commit, dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/contracts/" + contract.id + "/commentaires/", axiosConfig)
                commit('setCommentaires', response.data);
            } catch (e) {
                dispatch('alerts/handleAxiosError', e, {root: true})
            }
        },
        async postCommentaire({commit, dispatch, rootGetters}, {ticket, contract, commentaire}) {
            let auth = {username: rootGetters["users/getUsername"], password: rootGetters["users/getPassword"]};
            let axiosConfig = rootGetters["users/getLoggedUser"] !== null ? {auth} : {};
            var data = new FormData();
            for (const [key, value] of Object.entries(commentaire)) {
                if (value !== null) data.append(key, value);
            }
            let url = ticket !== null ?
                config.BASE_URL + "/api/tickets/" + ticket.id + "/commentaires/"
                : config.BASE_URL + "/api/contracts/" + contract.id + "/commentaires/";
            try {
                let response = await axios.post(url, data, {
                    headers: {
                        "Content-Type": "multipart/mixed",
                    },
                    ...axiosConfig,
                });
                var newCommentaire = response.data;
                commit("addCommentaire", newCommentaire);
                commit("setSelectedCommentaire", newCommentaire);
            } catch (e) {
                dispatch('alerts/handleAxiosError', e, {root: true})
            }
        },
        /**
         * Mettre à jour le commentaire
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param commentaire
         */
        async patchCommentaire({commit, dispatch, rootGetters}, commentaire) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/tickets/edit-commentaire/";
            let response = await axios.post(url, commentaire, axiosConfig);
            try {
                var newCommentaire = response.data;
                commit("updateCommentaire", newCommentaire);
                commit("setSelectedCommentaire", newCommentaire);
                return newCommentaire;
            } catch (e) {
                dispatch('alerts/handleAxiosError', e, {root: true});
            }
        },
        async deleteCommentaire({commit, rootGetters, dispatch}, commentaire) {
            const url = config.BASE_URL + "/api/tickets/delete-commentaire/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(url, commentaire, axiosConfig);
                commit("removeCommentaire", commentaire);
                commit("setSelectedCommentaire", null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        }
    },
    getters: {
        commentaires(state) {
            return state.commentaires;
        },
        getSelectedCommentaire(state) {
            return state.selected_commentaire;
        },
    },
};