import axios from "axios";
import config from "../config";
export const groupStore = {
    namespaced: true,
    state: () => ({
        "groups": [],
        "selected_group": null,
    }),
    mutations: {
        addGroup(state, group) {
            state.groups.push(group);
        },
        setGroups(state, groups) {
            state.groups = groups;
        },
        updateGroup(state, group) {
            state.groups = state.groups.map((item) => item.id === group.id ? group : item);
        },
        setSelectedGroup(state, group) {
            state.selected_group = group;
        }
    },
    actions: {
        async fetchGroups({commit, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let response = await axios.get(config.BASE_URL + "/api/core/groups/", axiosConfig)
            if (response.status === 200) {
                commit('setGroups', response.data);
                return "success";
            } else {
                throw Error("Impossible de charger les groups");
            }
        },
        postGroup({commit, rootGetters}, group) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            axios.post(config.BASE_URL + "/api/core/groups", group, axiosConfig)
                .then(function (response) {
                    if (response.status === 201) {
                        var newGroup = response.data["group"];
                        commit("addGroup", newGroup);
                        commit("setSelectedGroup", newGroup);
                    } else {
                        throw Error(response.data.toString());
                    }
                });
        },
        /**
         * Mettre à jour le group
         * @param commit
         * @param group
         */
        async putGroup({commit, rootGetters}, group) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/core/groups/" + group.id;
            console.log(url);
            console.log(group);
            let response = await axios.put(url, group, axiosConfig);
            if (response.status >= 200 && response.status <= 300) {
                var newGroup = response.data;
                commit("updateGroup", newGroup);
                commit("setSelectedGroup", newGroup);
                return newGroup;
            } else {
                return null;
            }
        }
    },
    getters: {
        groups(state) {
            return state.groups;
        },
        groups_intervenant(state) {
            return state.groups.filter(value => value.name === "Prestataire");
        },
        groups_others(state) {
            return state.groups.filter(value => value.name !== "Prestataire");
        },
        getSelectedGroup(state) {
            return state.selected_group;
        }
    }
};