import _toConsumableArray from "/var/www/projects/express/solutiafrontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
export var AssetFilterStore = {
  state: function state() {
    return {
      "search_name": null,
      "selected_category": null,
      "selected_site": null,
      "selected_site_tree": [],
      "site_dialog": false
    };
  },
  mutations: {
    setFilter: function setFilter(state, payload) {
      if (payload.filter === "name") {
        state.search_name = payload.value;
      } else if (payload.filter === "category") {
        state.selected_category = payload.value;
      }
    },
    resetFilters: function resetFilters(state) {
      state.search_name = null;
      state.selected_category = null;
      state.selected_site = null;
      state.selected_site_tree = [];
    },
    setSearchName: function setSearchName(state, value) {
      state.search_name = value;
    },
    setFilterCategory: function setFilterCategory(state, value) {
      state.selected_category = value;
    },
    setFilterSite: function setFilterSite(state, value) {
      state.selected_site = value;
    },
    setFilterSiteTree: function setFilterSiteTree(state, value) {
      state.selected_site_tree = value;
    },
    setSiteDialog: function setSiteDialog(state, value) {
      state.site_dialog = value;
    }
  },
  getters: {
    getFilteredAssets: function getFilteredAssets(state, getters) {
      return getters['allAssets'].filter(function (asset) {
        var _asset$site, _asset$site2;

        var valid = true;

        if (state.search_name !== null && state.search_name !== "" && asset.name.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
          valid = false;
        }

        if (state.selected_category !== null && asset.category === null) {
          valid = false;
        } else if (state.selected_category !== null && state.selected_category !== "" && asset.category.id !== state.selected_category) {
          valid = false;
        }

        if (state.selected_site !== null && asset.site === null) {
          valid = false;
        } else if (state.selected_site !== null && state.selected_site !== "" && ((_asset$site = asset.site) === null || _asset$site === void 0 ? void 0 : _asset$site.id) !== state.selected_site) {
          valid = false;
        } else if (state.selected_site_tree.length > 0 && state.selected_site_tree.indexOf((_asset$site2 = asset.site) === null || _asset$site2 === void 0 ? void 0 : _asset$site2.id) === -1 && state.selected_site_tree.indexOf("") === -1) {
          valid = false;
        }

        if (state.selected_site === null && state.selected_site_tree.length === 0 && (state.selected_category === null || state.selected_category === "") && (state.search_name === "" || state.search_name === null)) {
          valid = false;
        }

        return valid;
      });
    },
    getSearchName: function getSearchName(state) {
      return state.search_name;
    },
    getFilterCategory: function getFilterCategory(state) {
      return state.selected_category;
    },
    getFilterSite: function getFilterSite(state) {
      return state.selected_site;
    },
    getFilterSiteTree: function getFilterSiteTree(state) {
      return state.selected_site_tree;
    },
    getAssetsSites: function getAssetsSites(state, getters, rootState, rootGetters) {
      return [{
        "id": "",
        "name": "Tous les sites"
      }].concat(_toConsumableArray(rootGetters["sites/fullLocationSites"]));
    },
    getSiteDialog: function getSiteDialog(state) {
      return state.site_dialog;
    }
  },
  actions: {}
};