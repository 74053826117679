import axios from "axios";
import config from "../config";
import {FinanceStatStore} from "@/store/finance/stats";
import {FinanceFilterStore} from "@/store/finance/filter";

export const entryStore = {
    namespaced: true,
    state: () => ({
        "entries": [],
        "selected_entry": null,
    }),
    mutations: {
        addEntry(state, entry) {
            state.entries = [entry, ...state.entries];
        },
        setEntries(state, entries) {
            state.entries = entries;
        },
        updateEntry(state, entry) {
            state.entries = state.entries.map((item) => item.id === entry.id ? entry : item);
        },
        removeEntry(state, entry) {
            state.entries = state.entries.filter((item) => item.id !== entry.id);
        },
        setSelectedEntry(state, entry) {
            state.selected_entry = entry;
        }
    },
    actions: {
        async fetchEntries({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/finance/?include=sites", axiosConfig)
                commit('setEntries', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async getEntryById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/finance/${id}/?include=sites`, axiosConfig)
                commit('updateEntry', response.data);
                commit('setSelectedEntry', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postEntry({commit, dispatch, rootGetters}, entry) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + "/api/finance/", entry, axiosConfig);
                var newEntry = response.data;
                commit("addEntry", newEntry);
                commit("setSelectedEntry", newEntry);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        /**
         * Mettre à jour le entry
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param entry
         */
        async putEntry({commit, dispatch, rootGetters}, entry) {
            console.log("Passed");
            console.log(entry);
            const url = config.BASE_URL + "/api/finance/" + entry.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.patch(url, entry, axiosConfig);
                var newEntry = response.data;
                commit("updateEntry", newEntry);
                commit("setSelectedEntry", newEntry);
                return newEntry;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },

        async deleteEntry({commit, dispatch, rootGetters}, entry) {
            const url = config.BASE_URL + "/api/finance/" + entry.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.delete(url, axiosConfig);
                commit("removeEntry", entry);
                commit("setSelectedEntry", null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
    },
    getters: {
        allEntries(state) {
            return state.entries;
        },
        allDataEntries(state) {
            return state.entries.map((entry) => {
                return {
                    ...entry,
                    previsionnel: entry.previsionnel + " €",
                    realise: entry.realise + " €",
                }
            })
        },
        getSelectedEntry(state) {
            return state.selected_entry;
        },
        getEntryById(state) {
            return (id) => {
                return state.entries.find(entry => id === entry.id);
            };
        },
    },
    modules: {
        stats: FinanceStatStore,
        filter: FinanceFilterStore
    }
};