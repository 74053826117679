/*function site_name(ticket) {
    let siteObject = ticket.asset?.site;
    if (siteObject === null || siteObject === undefined) return "Non défini";
    let siteText = siteObject.name;
    if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
    }
    if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
    }
    return siteText;
}*/

export const TicketFilterStore = {
    namespaced: true,
    state: () => ({
        selectedDemandeur: "",
        selectedPriority: "",
        selectedStatut: "",
        search_name: "",
        selected_site_tree: [],
    }),
    mutations: {
        setFilter(state, payload) {
            if (payload.filter === "statut") {
                state.selectedStatut = payload.value;
            } else if (payload.filter === "priority") {
                state.selectedPriority = payload.value;
            } else if (payload.filter === "demandeur") {
                state.selectedDemandeur = payload.value;
            }
        },
        setSearchName(state, value) {
            state.search_name = value;
        },
        setSelectedSiteTree(state, value) {
            state.selected_site_tree = value;
        },
    },
    getters: {
        getFilteredTickets(state, getters, rootState, rootGetters) {
            return rootGetters["tickets/allTickets"].filter(function (ticket) {
                let valid = true;
                if (state.search_name !== null && state.search_name !== "" && ticket.objet.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                if(state.selected_site_tree.length !== 0
                    && state.selected_site_tree.indexOf("") === -1
                    && state.selected_site_tree.indexOf(ticket.asset?.site?.id) === -1) {
                    valid = false;
                }
                if (state.selectedDemandeur !== "" && ticket.created_by?.username !== state.selectedDemandeur) {
                    valid = false;
                }
                if (state.selectedPriority !== "" && ticket.priority !== state.selectedPriority) {
                    valid = false;
                }
                if (state.selectedStatut === "all" && valid !== false) {
                    valid = true;
                } else if (state.selectedStatut !== "" && ticket.state !== state.selectedStatut) {
                    valid = false;
                } else if (state.selectedStatut === "" && ["closed", "processed", "ready"].indexOf(ticket.state) !== -1) {
                    valid = false;
                }
                return valid;
            });
        },
        selectedDemandeur(state) {
            return state.selectedDemandeur;
        },
        selectedPriority(state) {
            return state.selectedPriority;
        },
        selectedStatut(state) {
            return state.selectedStatut;
        },
        getSearchName(state) {
            return state.search_name;
        },
        getSelectedSiteTree(state) {
            return state.selected_site_tree;
        }


    },
    actions: {
        resetFilters(context) {
            context.state.selectedDemandeur = "";
            context.state.selectedPriority = "";
            context.state.selectedStatut = "";
            context.state.search_name = "";
            context.state.selected_site_tree = [];
            context.commit("assets/setFilterSiteTree", [], {root: true})
        },
    },
}