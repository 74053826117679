import dayjs from "dayjs";

export const ContractFilterStore = {
    state() {
        return {
            "search_name": null,
            "search_type": "",
            "site_tree": [],
            "soon": false,
        }
    },
    mutations: {
        setFilter(state, payload) {
            if(payload.filter === "name") {
                state.search_name = payload.value;
            }
        },
        resetFilters(state) {
            state.search_name = null;
        },
        setSearchName(state, value) {
            state.search_name = value;
        },
        setSearchType(state, value) {
            state.search_type = value;
        },
        setSiteTree(state, value) {
            state.site_tree = value;
        },
        setSoon(state, value) {
            state.soon = value;
        },
    },
    getters: {
        getFilteredContracts(state, getters) {
            return getters['allContracts'].filter(function (contract) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && contract.name.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                if(state.search_type !== null && state.search_type !== "" && contract.type !== state.search_type) {
                    valid = false;
                }
                let site_ids = contract.assets.map(asset => asset.site?.id);
                let ids_to_show = state.site_tree.filter(site_id => site_ids.includes(site_id));
                if(state.site_tree.length > 0 && state.site_tree.indexOf("") === -1 && ids_to_show.length === 0) {
                    valid = false;
                }
                if(state.soon && dayjs(contract.date_end).add(-7, "day").isAfter(dayjs())) {
                    valid = false;
                }
                return valid;
            });
        },
        getSearchName(state) {
            return state.search_name;
        },
        getSearchType(state) {
            return state.search_type;
        },
        getSiteTree(state) {
            return state.site_tree;
        },
        getSoon(state) {
            return state.soon;
        }
    },
    actions: {},
}