import Home from "@/views/Home";

export const routes = [
    {
        path: '/',
        name: 'Tableau de bord',
        component: Home,
        meta: {title: "Accueil"}
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: {title: "A propos"}
    },
    {
        path: '/tickets',
        name: 'Tickets',
        component: () => import('../views/Tickets.vue'),
        meta: {title: "Tickets", permission: "view_ticket"},
        children: [
            {
                path: '/tickets/:id',
                name: 'Details du ticket',
                component: () => import('../views/TicketView.vue'),
                meta: {title: "Détails du ticket", permission: "view_ticket"}
            },
        ]
    },
    {
        path: '/interventions',
        name: 'Interventions',
        component: () => import('../views/Interventions.vue'),
        meta: {title: "Interventions", permission: "view_intervention"},
        children: [
            {
                path: '/interventions/:id',
                name: "Details de l'intervention",
                component: () => import('../views/InterventionView.vue'),
                meta: {title: "Détails de l'intervention", permission: "view_intervention"}
            },
        ]
    },
    {
        path: "/assets",
        name: "Équipements",
        component: () => import("../views/Assets.vue"),
        meta: {title: "Équipement", permission: "view_asset"},
        children: [
            {
                path: '/assets/:id',
                name: "Details de l'équipement",
                component: () => import('../views/AssetView.vue'),
                meta: {title: "Détails de l'équipement", permission: "view_asset"}
            },
        ]
    },
    {
        path: "/agenda",
        name: "Agenda",
        component: () => import('../views/Agenda.vue'),
        meta: {title: "Agenda"}
    },
    {
        path: "/contracts",
        name: "Contrats",
        component: () => import('../views/Contrats.vue'),
        meta: {title: "Contrats", permission: "view_contract"},
        children: [
            {
                path: '/contracts/:id',
                name: "Details du contrat",
                component: () => import('../views/ContratView.vue'),
                meta: {title: "Détails du contrat", permission: "view_contract"}
            },
        ]
    },
    {
        path: "/intervenants",
        name: "Intervenants",
        component: () => import('../views/Intervenants.vue'),
        meta: {title: "Intervenants", permission: "view_profile"},
        children: [
            {
                path: '/intervenants/:id',
                name: "Details de l'intervenant",
                component: () => import('../views/IntervenantView.vue'),
                meta: {title: "Détails du l'intervenant", permission: "view_profile"}
            },
        ]
    },
    {
        path: "/login",
        name: "Se connecter",
        component: () => import('../views/Login.vue'),
        meta: {title: "Se connecter"},
    },
    {
        path: "/admin",
        name: "Administration",
        component: () => import('../views/Admin.vue'),
        meta: {title: "Administration", permission: "view_config"},
    },
    {
        path: "/profil",
        name: "Profil",
        component: () => import('../views/Profile.vue'),
        meta: {title: "Profil"},
    },
    {
        path: "/finance",
        name: "Finances",
        component: () => import('../views/Finances.vue'),
        meta: {title: "Finances", permission: "view_entry"},
    },
    {
        path: "/stats",
        name: "Statistiques",
        component: () => import('../views/Statistiques.vue'),
        meta: {title: "Statistiques", permission: "view_entry"},
    },
    {
        path: "/forgotpassword",
        name: "Mot de passe oublié",
        component: () => import('../views/PasswordForgotten.vue'),
        meta: {title: "Mot de passe oublié"},
    },
    {
        path: "/resetpassword",
        name: "Réinitialisation du mot de passe",
        component: () => import('../views/PasswordReset.vue'),
        meta: {title: "Mot de passe oublié"},
    },
    {
        path: "/help",
        name: "Aide et support",
        component: () => import('../views/Help.vue'),
        meta: {title: "Aide et support"},
    },

];

export function getAllRoutes(appRoutes) {
    let allRoutes = [];
    for (let route of appRoutes) {
        if(route.children !== undefined && route.children?.length > 0) {
            allRoutes = [...allRoutes, ...getAllRoutes(route.children)]
        }
        allRoutes = [...allRoutes, route];
    }
    return allRoutes;
}

