<template>
  <v-card>
    <v-app-bar color="primary" dark>
      <v-card-title class="white--text">Scanner</v-card-title>
    </v-app-bar>

    <v-card-text class="pa-0">
      <div id="scanner">
        <video class="videoCamera" autoplay="true" preload="auto" src="" muted="true"
               playsinline="true"></video>
        <canvas class="drawingBuffer"></canvas>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn text color="primary" @click="closeScanner">Fermer</v-btn>
      <v-btn text color="primary" @click="loadScanner">Activer</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Quagga from 'quagga'

export default {
  name: 'BarcodeScanner',
  props: ["dialogVisibility"],
  mounted() {
    this.loadScanner();
  },
  methods: {
    closeScanner() {
      Quagga.stop();
      this.$emit("close-scanner-dialog");
    },
    loadScanner() {
      Quagga.init(
          {
            inputStream: {
              name: "Live",
              type: "LiveStream",
              target: document.getElementById("scanner")    // Or '#yourElement' (optional)
            },
            decoder: {
              readers: ["code_128_reader"]
            }
          }, function (err) {
            if (err) {
              console.log(err);
              return
            }
            Quagga.start();
          });
      let that = this;
      Quagga.onDetected(async function (result) {
        var code = result.codeResult.code;
        let corresponding_assets = await that.$store.dispatch("assets/getAssetByBalise", code);
        console.log(corresponding_assets);
        if(corresponding_assets.length > 0) {
          let asset = corresponding_assets[0];
          that.closeScanner();
          await that.$router.push(`/assets/${asset?.id}`);
        } else {
          await that.$store.dispatch("messages/announceError", "Aucun équipement correspondant trouvé.")
        }
      });
    }
  },
  watch: {
    dialogVisibility: function (oldState, newState) {
      if (newState === false) {
        Quagga.stop();
      }
    }
  }
}
</script>
<style lang="scss">
$body-font-family: 'Trebuchet';
$title-font: 'Helvetica';
.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
}
</style>