import Vue from 'vue'
import dayjs from "dayjs";
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import 'dayjs/locale/fr';

import '@/assets/sass/variables.sass';

dayjs.locale("fr");
Vue.config.productionTip = false

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import config from "@/config";
import VueGtag from "vue-gtag";
import axios from "axios";

axios.defaults.headers.post['Content-Type'] = 'application/json';

if(config.MODE === "production") {
  Vue.use(VueGtag, {
    config: { id: config.GA_CODE }
  }, router);
  Sentry.init({
    dsn: "https://199487083cf34fc2bb99baa49863d170@o480296.ingest.sentry.io/5667149",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.25,
  });
}

window.vueapp = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
