import dayjs from "dayjs";

export const FinanceFilterStore = {
    namespaced: true,
    state() {
        return {
            display_contract: false,
            filter_type: "",
            filter_depense: "",
            filter_year: null,
            filter_site_tree: [],
        };
    },
    mutations: {
        setDisplayContract(state, value) {
            state.display_contract = value;
        },
        setFilterType(state, value) {
            state.filter_type = value;
        },
        setFilterDepense(state, value) {
            state.filter_depense = value;
        },
        setFilterYear(state, value) {
            state.filter_year = value;
        },
        setFilterSiteTree(state, value) {
            state.filter_site_tree = value;
        },
    },
    getters: {
        getDisplayContract(state) {
            return state.display_contract;
        },
        getFilterType(state) {
            return state.filter_type;
        },
        getFilterDepense(state) {
            return state.filter_depense;
        },
        getFilterYear(state) {
            return state.filter_year;
        },
        getFilterSiteTree(state) {
            return state.filter_site_tree;
        },
        getFilteredEntries(state, getters, rootState, rootGetters) {
            return rootGetters["finance/allEntries"].filter(function (entry) {
                let valid = true;
                if(state.display_contract === false && entry.type_depense === 'Dépense sous contrat') {
                    valid = false;
                }
                if(state.filter_type !== "" && entry.type_depense !== state.filter_type) {
                    valid = false;
                }
                if(state.filter_depense === 'previsionnel' && parseFloat(entry.previsionnel) === 0) {
                    valid = false;
                }

                if(state.filter_depense === 'realise' && parseFloat(entry.realise) === 0) {
                    valid = false;
                }
                if(state.filter_year !== null && dayjs(entry.date_gestion).year() !== parseInt(state.filter_year)) {
                    valid = false;
                }
                if(state.filter_site_tree.length > 0 && state.filter_site_tree.indexOf("") === -1 && state.filter_site_tree.indexOf(entry.site_id)) {
                    valid = false;
                }
                return valid;
            })
        }
    }
}