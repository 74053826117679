import axios from "axios";
import config from "../config";

export const userStore = {
    namespaced: true,
    state: () => ({
        "users": [],
        "selected_user": null,
        "logged_user": null,
        "username": "",
        "password": "",
    }),
    mutations: {
        addUser(state, user) {
            state.users.push(user);
        },
        setUsers(state, users) {
            state.users = users;
        },
        updateUser(state, user) {
            state.users = state.users.map((item) => item.id === user.id ? user : item);
        },
        setSelectedUser(state, user) {
            state.selected_user = user;
        },
        setLoggedUser(state, user) {
            state.logged_user = user;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setPassword(state, password) {
            state.password = password;
        },
    },
    actions: {
        fetchUsers({commit, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            axios.get(config.BASE_URL + "/api/core/users/", axiosConfig)
                .then(function (response) {
                    if (response.status === 200) {
                        commit('setUsers', response.data);
                    } else {
                        throw Error("Impossible de charger les users");
                    }
                });
        },
        postUser({commit, rootGetters}, user) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            axios.post(config.BASE_URL + "/api/core/users", user, axiosConfig)
                .then(function (response) {
                    if (response.status === 201) {
                        var newUser = response.data["user"];
                        commit("addUser", newUser);
                        commit("setSelectedUser", newUser);
                    } else {
                        throw Error(response.data.toString());
                    }
                });
        },
        /**
         * Mettre à jour le user
         * @param commit
         * @param user
         */
        async putUser({commit, rootGetters}, user) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/core/users/" + user.id;
            console.log(url);
            console.log(user);
            let response = await axios.put(url, user, axiosConfig);
            if (response.status >= 200 && response.status <= 300) {
                var newUser = response.data;
                commit("updateUser", newUser);
                commit("setSelectedUser", newUser);
                return newUser;
            } else {
                return null;
            }
        },
        async login({commit}, loginInfo) {
            const url = config.BASE_URL + "/api/core/users/me/";
            try {
                let response = await axios.get(url, {
                    auth: loginInfo,
                });
                if (response.status >= 200 && response.status <= 300) {
                    var loggedUser = response.data;
                    commit("setLoggedUser", loggedUser);
                    commit("setUsername", loginInfo.username);
                    commit("setPassword", loginInfo.password);
                    sessionStorage["username"] = loginInfo.username;
                    sessionStorage["password"] = loginInfo.password;
                    if (loginInfo.rememberMe) {
                        localStorage["username"] = loginInfo.username;
                        localStorage["password"] = loginInfo.password;
                    }
                    return "success";
                } else {
                    return "failure"
                }
            } catch (e) {
                return "failure";
            }
        },
        logout({commit}) {
            commit("setLoggedUser", null);
            commit("setUsername", "");
            commit("setPassword", "");
            sessionStorage["username"] = null;
            sessionStorage["password"] = null;
            localStorage["username"] = null;
            localStorage["password"] = null;
            return "success";
        },
        async isSessionLogged({state, dispatch}) {
            let username = sessionStorage.getItem("username");
            let password = sessionStorage.getItem("password");
            if (username === null || password === null) {
                return false;
            } else {
                if (state.logged_user !== null) {
                    return true;
                }
                try {
                    return await dispatch("login", {username, password}) === "success";
                } catch (e) {
                    return false;
                }
            }
        },
        async reloadUserFromSession({dispatch}) {
            let username = sessionStorage.getItem("username");
            let password = sessionStorage.getItem("password");
            try {
                return await dispatch("login", {username, password,});
            } catch (e) {
                return "failure";
            }
        },
        async isStorageLogged({dispatch}) {
            let username = localStorage.getItem("username");
            let password = localStorage.getItem("password");
            if (username === null || username === "null") {
                return false;
            } else {
                try {
                    return await dispatch("login", {username, password}) === "success";
                } catch (e) {
                    return false;
                }
            }
        },
        hasPermission(context, codename) {
            let hasPermission = context.state.logged_user.user_permissions.find(permission => permission.codename === codename) !== undefined;
            return hasPermission;
        }
    },
    getters: {
        users(state) {
            return state.users;
        },
        prestataires(state) {
            return state.users.filter((item) => item.group === "Prestataire");
        },
        collaborateurs(state) {
            return state.users.filter(item => item.group === "Collaborateur");
        },
        getSelectedUser(state) {
            return state.selected_user;
        },
        getLoggedUser(state) {
            return state.logged_user;
        },
        getUsername(state) {
            return state.username;
        },
        getPassword(state) {
            return state.password;
        },
        getLoginConfig(state) {
            return state.username !== null && state.username !== "" ? {
                auth: {
                    username: state.username,
                    password: state.password
                }
            } : {};
        },
        authentificationConfig(state) {
            let auth = {username: state.username, password: state.password};
            return state.logged_user !== null ? {auth} : {};
        },
        hasPermission(state) {
            return function (codename) {
                return state.logged_user?.user_permissions
                    .find(permission => permission.codename === codename) !== undefined;
            };
        }
    }
};